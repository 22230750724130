var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "mt": "10px"
    }
  }, [_vm._l(_vm.steps, function (step, index) {
    return [index === _vm.stepIndex ? _c('CardStep', {
      key: index,
      attrs: {
        "current": index + 1,
        "total": _vm.steps.length,
        "is-prev": index !== 0,
        "is-next": true
      },
      on: {
        "prev": function prev($event) {
          _vm.stepIndex--;
        },
        "next": function next($event) {
          return _vm.onNext();
        }
      },
      scopedSlots: _vm._u([{
        key: "next",
        fn: function fn() {
          return [index === _vm.steps.length - 1 ? _c('c-text', [_vm._v(" Submit ")]) : _c('c-text', [_vm._v(" Selanjutnya ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('c-text', {
      attrs: {
        "color": "primary.400"
      }
    }, [_vm._v(" Pilih Indikator Pertanyaan ")]), _c('c-text', {
      attrs: {
        "font-size": "xl",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]), _c('c-flex', {
      attrs: {
        "mt": "30px",
        "flex-dir": "column"
      }
    }, _vm._l(step.questions, function (item, index_) {
      return _c('c-checkbox', {
        key: index_,
        attrs: {
          "is-disabled": !item.editable,
          "variant-color": "primary"
        },
        model: {
          value: item.checked,
          callback: function callback($$v) {
            _vm.$set(item, "checked", $$v);
          },
          expression: "item.checked"
        }
      }, [_c('c-flex', [_vm.isDevelopment ? [_c('small', [_vm._v("(" + _vm._s(item.id) + ")")])] : _vm._e(), _c('c-text', [_vm._v(_vm._s(item.question))])], 2)], 1);
    }), 1)], 1) : _vm._e()];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }